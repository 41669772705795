@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
.landing-section-container {
  background-image: url("../../../../assets/images/landingImage.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 1000px;
  color: white;
  margin-top: -64px;
}

@media (max-width: 700px) {
  .landing-section-container {
    height: 100vh;
    margin-top: -56px;
  }
}

.landing-section-container .text-container {
  font-family: 'Roboto', sans-serif;
  width: 400px;
}

@media (max-width: 700px) {
  .landing-section-container .text-container {
    text-align: center;
    width: 100%;
    padding: 10px;
    font-size: 20px;
  }
}

.landing-section-container .text-container .icon {
  width: 250px;
}

@media (max-width: 700px) {
  .landing-section-container .text-container .icon {
    width: 70%;
  }
}

.landing-section-container .icon-container {
  width: 15rem;
}

@media (max-width: 700px) {
  .landing-section-container .icon-container {
    display: none;
  }
}

.landing-section-container .icon-container .icon {
  display: block;
  width: 100%;
}
