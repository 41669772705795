.button-container-1 {
  position: relative;
  padding: 10px 50px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 16px;
  border: none;
  border-radius: 50px;
  color: white;
  background-color: transparent;
  border-style: solid;
  border-width: 5px;
  border-color: #7cce82;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 60px;
  font-size: 25px;
}

.button-container-1:hover {
  background-color: #7cce82;
  cursor: pointer;
}
