@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
.home-page-other-container {
  background-image: url("../../../../assets/images/homepageBackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-page-other-container .before-main-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 200px;
}

@media (max-width: 700px) {
  .home-page-other-container .before-main-section {
    display: none;
  }
}

.home-page-other-container .before-main-section .optimx-names {
  width: 90%;
  margin: 0;
}

.home-page-other-container .main-section {
  width: 80%;
  height: auto;
  overflow: hidden;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section {
    width: 100%;
    border-radius: 0;
    margin: 0;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .home-page-other-container .main-section {
    width: 90%;
  }
}

.home-page-other-container .main-section .top-image {
  background-image: url("../../../../assets/images/homepageTopImage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .top-image {
    height: 300px;
  }
}

.home-page-other-container .main-section .top-image .top-image-section {
  height: 60%;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .top-image .top-image-section {
    width: 90%;
    height: 80%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .home-page-other-container .main-section .top-image .top-image-section {
    width: 90%;
  }
}

.home-page-other-container .main-section .top-image .top-image-section .top-image-section-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 25%;
  height: 100%;
  text-align: left;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .top-image .top-image-section .top-image-section-section {
    width: 90%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

.home-page-other-container .main-section .top-image .top-image-section .top-image-section-section .top-image-section-section-icon {
  padding: 0;
  padding-right: 1rem;
}

.home-page-other-container .main-section .second-top-image {
  background-image: url("../../../../assets/images/homepageSecondTopImage.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: 'Roboto', sans-serif;
}

.home-page-other-container .main-section .other-sections {
  height: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .other-sections {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100vh;
    padding: 0;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .home-page-other-container .main-section .other-sections {
    height: 450px;
  }
}

.home-page-other-container .main-section .other-sections.other-sections-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .other-sections.other-sections-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.home-page-other-container .main-section .other-sections.other-sections-only-text {
  width: 90%;
}

.home-page-other-container .main-section .other-sections.other-sections-only-text .text-left {
  width: 90%;
}

.home-page-other-container .main-section .other-sections .image {
  border-style: solid;
  border-width: 1px;
  border-color: black;
  border-radius: 20px;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .other-sections .image {
    width: 70%;
    max-height: 40vh;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  .home-page-other-container .main-section .other-sections .image {
    width: 40%;
  }
}

.home-page-other-container .main-section .other-sections .text-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  text-align: left;
  width: 50%;
  text-align: left;
  letter-spacing: 0.05rem;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .other-sections .text-left {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    width: 90%;
  }
}

.home-page-other-container .main-section .other-sections .text-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  text-align: left;
  width: 50%;
  text-align: right;
  letter-spacing: 0.05rem;
}

@media (max-width: 700px) {
  .home-page-other-container .main-section .other-sections .text-right {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    width: 90%;
  }
}

@media (max-width: 1200px) {
  .home-page-other-container .main-section .other-sections .link .text {
    font-size: 13px;
    padding: 20px 10px;
    letter-spacing: 0.1rem;
  }
}

.home-page-other-container .main-section .other-sections .link:hover {
  cursor: pointer;
}

.home-page-other-container .second-bottom-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #151d29;
  height: 200px;
}

@media (max-width: 700px) {
  .home-page-other-container .second-bottom-section {
    height: 60px;
  }
}

.home-page-other-container .second-bottom-section .optimx-names {
  width: 90%;
}
